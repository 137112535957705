import React from 'react';
import PropTypes from 'prop-types';
import Imgix from 'react-imgix';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/technology.scss';
import NavigationItem from '../components/NavigationItem';
import Aim from '../components/Aim';
import useInlineParsingV2 from '../utils/inline-parsing.utils';
import TeamMember from '../components/TeamMember';
import AdvisoryBoard from '../components/AdvisoryBoard';
import Partner from '../components/Partner';
import NextItem from '../components/NextItem';


const TechnologyTemplate = ({ pageContext }) => {
  const { content, references } = pageContext;
  const mountedContent = useInlineParsingV2(content);
  let mountedReferences = null;
  if (references !== null) {
    mountedReferences = useInlineParsingV2(references)
  }

  const { meta, page_contents, og_image, page_slug } = pageContext.metadata;
  return (
    <Layout>
      <SEO title="404: Not found" canonical="/404/" />
      <div className="four-oh-four-container">
        <div className="four-oh-four-text-container">
          <h4>VINEX</h4>
          <h1>404 Error - <br/>Page Not Found</h1>
        </div>
      </div>
    </Layout>
  )
  // return (
  //   <Layout pathName={pageContext.pathname}>
  //     <SEO pathname={page_slug} canonical={page_slug} title={meta.meta_title} description={meta.meta_description} ogImage={og_image.imgix_url || null} />
  //     <div className={`technology__top-container ${page_contents.metadata.top_background_image && page_contents.metadata.top_background_image.imgix_url ? 'has-top-bg__mobile' : ''}`}>
  //       {/* {page_contents.metadata.top_background_image && (
  //         <Background
  //           className="vinex__image-background"
  //           imgixParams={{w: 1600, h: 852 }}
  //           src={`${page_contents.metadata.top_background_image.imgix_url}?auto=format`}
  //         />
  //       )} */}
  //       <div className="technology__contents-container">
  //         {page_contents && page_contents.metadata && page_contents.metadata.next_page && page_contents.metadata.next_page.metadata && (
  //           <NextItem nextPage={page_contents.metadata.next_page} />
  //         )}
  //         <div className="technology__contents-inner-container">
  //           <div className="technology__main-title" dangerouslySetInnerHTML={{ __html: page_contents.metadata.main_title}} />
  //           <div className="technology__columned-content">
  //             {page_contents.metadata.column_1_content && (<div className="technology__column-1" dangerouslySetInnerHTML={{ __html: page_contents.metadata.column_1_content }} />)}
  //             {page_contents.metadata.column_2_content && (<div className="vinex__column-2" dangerouslySetInnerHTML={{ __html: page_contents.metadata.column_2_content }} />)}
  //           </div>
  //         </div>
  //         {page_contents.metadata.top_background_image && page_contents.metadata.top_background_image.imgix_url && (<div className="technology__top-image">
  //           <Imgix
  //             className="lazyload blur-up image"
  //             width={734}
  //             height={747}
  //             htmlAttributes={{
  //               alt: 'Rocket Health Science | Technology',
  //               src: `${page_contents.metadata.top_background_image.imgix_url}?blur=500&px=4&auto=format`,
  //             }}
  //             src={`${page_contents.metadata.top_background_image.imgix_url}?auto=format`}
  //           />
  //         </div>)}
  //       </div>
  //     </div>
      
  //     {page_contents.metadata.team_members && (
  //       <section className="technology__team-members-container">
  //         <h4 className="team-header">OUR TEAM</h4>
  //         <div className="team-members-container">
  //           {page_contents.metadata.team_members.map((member, index) => (
  //             <TeamMember member={member} key={`member-${index}`} />
  //           ))}
  //         </div>
  //       </section>
  //     )}
  //     {page_contents.metadata.advisory_board && (
  //       <section className="technology__advisory-container">
  //         <h4 className="advisor-header">ADVISORY BOARD</h4>
  //         <div className="advisory-container">
  //           {page_contents.metadata.advisory_board.map((advisor, index) => (
  //             <AdvisoryBoard advisoryMember={advisor} key={`advisor-${index}`} />
  //           ))}
  //         </div>
  //       </section>
  //     )}
  //     {page_contents.metadata.supporters && (
  //       <section className="technology__supporter-container">
  //         <h4 className="advisor-header">SUPPORTERS</h4>
  //         <div className="advisory-container">
  //           {page_contents.metadata.supporters.map((supporter, index) => (
  //             <Partner
  //               content={supporter.metadata.supporter_description}
  //               image={supporter.metadata.supporter_image.imgix_url}
  //               title={supporter.title}
  //               key={`supporter-${index}`}
  //             />
  //           ))}
  //         </div>
  //       </section>
  //     )}
  //     {page_contents.metadata.partners && (
  //       <section className="technology__supporter-container">
  //         <h4 className="advisor-header">PARTNERS</h4>
  //         <div className="advisory-container">
  //           {page_contents.metadata.partners.map((partner, index) => (
  //             <Partner
  //               title={partner.title}
  //               content={partner.metadata.partner_description}
  //               image={partner.metadata.partner_image.imgix_url}
  //               key={`partner-${index}`}
  //             />
  //           ))}
  //         </div>
  //       </section>
  //     )}
  //     {page_contents.content && mountedContent && (
  //       <section className="technology__content-container">
  //         {mountedContent.length > 0 && mountedContent.map((articleContent, index) => {
  //           switch (articleContent.type) {
  //             case 'html':
  //               return (
  //                 <div
  //                   key={`${articleContent.type}-${index}`}
  //                   id={`${articleContent.index}`}
  //                   className="technology__main-content"
  //                   dangerouslySetInnerHTML={{ __html: articleContent.content }}
  //                 />
  //               );
  //             case 'jsx':
  //               return articleContent.content;
  //             default:
  //               return '';
  //           }
  //         })}
  //       </section>
  //     )}
  //     {page_contents.metadata.content_quote_section && page_contents.metadata.content_quote_section.content_left !== '' && (
  //       <section className="technology__content-quote-container">
  //         <div className="technology__column-1" dangerouslySetInnerHTML={{ __html: page_contents.metadata.content_quote_section.content_left }} />
  //         <div className="technology__column-2" dangerouslySetInnerHTML={{ __html: page_contents.metadata.content_quote_section.content_right }} />
  //       </section>
  //     )}
  //     {page_contents.metadata.aims_and_challenges && page_contents.metadata.aims_and_challenges.items && page_contents.metadata.aims_and_challenges.items.length > 0 && (
  //       <section className="technology__aims-challenges-container">
  //         <h4>{page_contents.metadata.aims_and_challenges.title}</h4>
  //         <div className="challenge-items-container">
  //           {page_contents.metadata.aims_and_challenges && page_contents.metadata.aims_and_challenges.items.map((item, index) => (
  //             <Aim
  //               columnCount={page_contents.metadata.aims_and_challenges.items.length}
  //               key={`aim-${index}`}
  //               title={item.item_title}
  //               content={item.item_description}
  //             />
  //           ))}
  //         </div>
  //       </section>
  //     )}
  //     {/* {page_contents.metadata.quote && (
  //       <section className="home__quote-container">
  //         <BottomQuoteCTA
  //           image={page_contents.metadata.quote.image !== null && page_contents.metadata.quote.image.imgix_url}
  //           quote={page_contents.metadata.quote.quote_text}
  //           author={page_contents.metadata.quote.author}
  //         />
  //       </section>
  //     )} */}
  //     {page_contents.metadata.navigation_items && page_contents.metadata.navigation_items.items && (
  //       <section className="technology__stories-container">
  //         {page_contents.metadata.navigation_items.items && page_contents.metadata.navigation_items.items.map((navItem, index) => (
  //           <NavigationItem
  //             key={`navigation-${index}`}
  //             url={navItem.metadata.page_slug}
  //             image={navItem.metadata.navigation_image.imgix_url}
  //             title={navItem.metadata.navigation_title}
  //             short_text={navItem.metadata.navigation_short_text}
  //           />
  //         ))}
  //       </section>
  //     )}
  //     {page_contents.metadata.references && mountedReferences && (
  //       <section id="references" className="technology__references-container">
  //         <h4 className="advisor-header">REFERENCES</h4>
  //         <div className="technology__reference-text-container">
  //           {mountedReferences.length > 0 && mountedReferences.map((mountedReference, index) => {
  //             switch (mountedReference.type) {
  //               case 'html':
  //                 return (
  //                   <div
  //                     key={`${mountedReference.type}-${index}`}
  //                     id={`${mountedReference.index}`}
  //                     className="technology__main-content reference__content"
  //                     dangerouslySetInnerHTML={{ __html: mountedReference.content }}
  //                   />
  //                 );
  //               case 'jsx':
  //                 return mountedReference.content;
  //               default:
  //                 return '';
  //             }
  //           })}
  //         </div>
  //       </section>
  //     )}
  //   </Layout>
  // )
};

TechnologyTemplate.propTypes = {
  pageContext: PropTypes.any,
};

TechnologyTemplate.defaultProps = {
  pageContext: {},
};

export default TechnologyTemplate;
